import defaultAxios from 'axios'

import type { AxiosError } from 'axios'
import type { IncomingHttpHeaders } from 'http'

const { create } = defaultAxios

export const isAxiosError = <T = any>(payload: any): payload is AxiosError<T> => !!payload.isAxiosError

export const serverAxios = (browserHeaders: IncomingHttpHeaders) => {
  const baseURL = process.env.NEXT_PUBLIC_BASE_ENDPOINT
  const xCsrfToken = (() => {
    const xCsrfStr = browserHeaders.cookie?.split(';').find(v => v.includes('X-CSRFToken'))
    const csrfValue = xCsrfStr?.split('=')[1]
    return csrfValue
  })()
  const headers = {
    Referer: 'https://rentalcar-api.api-uandcompany.com',
    Cookie: browserHeaders.cookie ?? '',
    'X-CSRFToken': xCsrfToken ?? ''
  }
  const withCredentials = true

  const axios = create({
    baseURL,
    withCredentials,
    headers
  })

  return axios
}

export const axios = create({
  withCredentials: true
})
