import '@/styles/globals.css'
import Head from 'next/head'

import { Providers } from '@/providers/Providers'

import type { AppPropsWithLayout } from '@/types/app'
import type { NextPage } from 'next'

if (process.env.NEXT_PUBLIC_MOCK === 'true') {
  const MockServer = () => import('@/mocks/dev/index')
  MockServer()
}

const App: NextPage<AppPropsWithLayout> = ({ Component, pageProps }) => {
  const getLayout = Component.getLayout ?? (page => page)

  return (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>

      <Providers>{getLayout(<Component {...pageProps} />)}</Providers>
    </>
  )
}

export default App
