import { HourglassTopSharp } from '@mui/icons-material'
import { Backdrop, keyframes } from '@mui/material'

import { useGlobalOverlayLoading } from '@/hooks/useGlobalOverlayLoading'

import type { FC } from 'react'

const bound = keyframes`
  0% {
    transform: scale(1.0, 1.0) translate(0%, 0%);
  }
  15% {
    transform: scale(0.9, 0.9) translate(0%, 5%);
  }
  30% {
    transform: scale(1.3, 0.8) translate(0%, 10%);
  }
  50% {
    transform: scale(0.8, 1.3) translate(0%, -10%);
  }
  70% {
    transform: scale(1.1, 0.9) translate(0%, 5%);
  }
  100% {
    transform: scale(1.0, 1.0) translate(0%, 0%);
  }
}
`

export const LoadingProvider: FC = ({ children }) => {
  const { isOpenGlobalOverlayLoading } = useGlobalOverlayLoading()

  return (
    <>
      <Backdrop sx={{ color: '#fff', zIndex: theme => theme.zIndex.modal + 1 }} open={isOpenGlobalOverlayLoading}>
        <HourglassTopSharp sx={{ fontSize: '6rem', animation: `${bound} 0.8s linear 0s infinite` }} />
      </Backdrop>
      {children}
    </>
  )
}
