import { AuthProvider } from '@/providers/AuthProvider'
import { LoadingProvider } from '@/providers/LoadingProvider'
import { StyleProvider } from '@/providers/StyleProvider'

import type { FC } from 'react'

export const Providers: FC = ({ children }) => (
  <StyleProvider>
    <AuthProvider>
      <LoadingProvider>{children}</LoadingProvider>
    </AuthProvider>
  </StyleProvider>
)
