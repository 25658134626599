import { useRouter } from 'next/router'
import { useCallback, useEffect } from 'react'

import { GlobalLoading } from '@/components/molecules/GlobalLoading'
import { useAuth } from '@/hooks/useAuth'
import { isAxiosError } from '@/libs/axios'

import type { ErrorDto } from '@/types/api/error'
import type { FC } from 'react'

export const AuthProvider: FC = ({ children }) => {
  const router = useRouter()
  const { authInfo, getSession } = useAuth()

  const isRequiredAuth = router.pathname !== '/login'
  const isAuthenticated = !!authInfo?.isAuthenticated

  // 認証が必要のないページ（ログインページ）に認証状態でアクセスした時
  const isLoginPageWithAuthenticated = !isRequiredAuth && isAuthenticated
  // 認証が必要なページに非認証状態でアクセスした時
  const isAuthRequiredPageWithNoAuthenticated = isRequiredAuth && !isAuthenticated

  // ローディングを表示する条件
  const isNeedToWait = !authInfo || isLoginPageWithAuthenticated || isAuthRequiredPageWithNoAuthenticated

  const handleRenderFirstView = useCallback(async () => {
    try {
      await getSession()
    } catch (err) {
      if (!isAxiosError<ErrorDto>(err)) {
        throw new Error(JSON.stringify(err))
      }

      if (isAuthRequiredPageWithNoAuthenticated) {
        alert('セッションが見つかりませんでした、ログイン画面に移動します。')
      }
    }
  }, [getSession, isAuthRequiredPageWithNoAuthenticated])

  useEffect(() => {
    // NOTE: 初回レンダリング時はqueryなどが空オブジェクトで渡ってきて、その後フロント側でrouterオブジェクトを再生成するためこのハンドリングをしていないと二回処理が走ってしまう。
    if (!router.isReady) {
      return
    }

    // ファーストビューでセッション確認
    if (!authInfo) {
      handleRenderFirstView()
      return
    }

    // 認証状態でのログインページアクセス時
    if (isLoginPageWithAuthenticated) {
      router.replace('/')
      return
    }

    // 非認証状態での認証が必要なページへのアクセス時
    if (isAuthRequiredPageWithNoAuthenticated) {
      router.replace('/login')
    }
  }, [router, handleRenderFirstView, authInfo, isLoginPageWithAuthenticated, isAuthRequiredPageWithNoAuthenticated])

  return isNeedToWait ? <GlobalLoading /> : <>{children}</>
}
