import { useCallback } from 'react'
import useSWRImmutable from 'swr/immutable'

import { axios } from '@/libs/axios'

import type { AuthDto } from '@/types/api/auth'
import type { AuthInput } from '@/types/form/auth'

export const useAuth = () => {
  const { data: authInfo, mutate } = useSWRImmutable<AuthDto>('useAuth', null)
  const updateAuthInfo = useCallback(
    (authInfo: AuthDto) => {
      mutate(authInfo, false)
    },
    [mutate]
  )

  const getXCsrf = useCallback(async () => {
    await axios.get('/api/auth/xcsrf')
  }, [])

  const signIn = useCallback(
    async ({ id: username, password }: AuthInput) => {
      const { data } = await axios.post<AuthDto>('/api/auth/login', {
        username,
        password
      })
      await getXCsrf()
      updateAuthInfo(data)
    },
    [getXCsrf, updateAuthInfo]
  )

  const signOut = useCallback(async () => {
    await axios.post<AuthDto>('/api/auth/logout')
    updateAuthInfo({ isAuthenticated: false })
  }, [updateAuthInfo])

  const checkSession = useCallback(async () => {
    try {
      const { data } = await axios.post<AuthDto>('/api/auth/session')
      updateAuthInfo(data)
    } catch (err) {
      updateAuthInfo({ isAuthenticated: false })
      throw err
    }
  }, [updateAuthInfo])

  const getSession = useCallback(async () => {
    await getXCsrf()
    await checkSession()
  }, [getXCsrf, checkSession])

  return {
    authInfo,
    signIn,
    signOut,
    getSession
  }
}
