import '@fontsource/roboto/300.css'
import '@fontsource/roboto/400.css'
import '@fontsource/roboto/500.css'
import '@fontsource/roboto/700.css'

import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { colors, CssBaseline, Box } from '@mui/material'

import type { FC } from 'react'

export const StyleProvider: FC = ({ children }) => {
  return (
    <>
      <CssBaseline />
      <Box sx={{ width: '100%', height: '100%', backgroundColor: colors.grey[50] }}>{children}</Box>
    </>
  )
}
