import { useCallback } from 'react'
import useSWRImmutable from 'swr/immutable'

export const useGlobalOverlayLoading = () => {
  const { data, mutate } = useSWRImmutable('useGlobalOverlayLoading', null, {
    fallbackData: false
  })

  const isOpenGlobalOverlayLoading = !!data
  const updateIsOpenGlobalOverlayLoading = useCallback(
    async (isOpen: boolean) => {
      await mutate(isOpen, false)
    },
    [mutate]
  )

  return {
    isOpenGlobalOverlayLoading,
    updateIsOpenGlobalOverlayLoading
  }
}
